<template>
  <div class="sector-wrapper">
    <div class="mb-32 component__header">
      <h1>{{ sectorTypes[type] || "" }} क्षेत्र</h1>
    </div>
    <div class="container sector__content">
      <SectorDescription :type="type" />
      <div class="mb-48 go-to-form">
        <span @click="formModalOn" class="open-form"
          >गुनासो/प्रतिक्रिया सम्बन्धित फारम मा जानुहोस</span
        >
      </div>
      <tabs :options="{ useUrlFragment: false }">
        <tab :name="`${sectorTypes[type]} डायरी`">
          <SectorBlog />
        </tab>
        <tab :name="`${sectorTypes[type]} क्षेत्र सम्बन्धित सुचना`">
          <div class="sector__stats">
            <div class="flex title-filter mb-8">
              <h3>{{ sectorTypes[type] }} क्षेत्र सम्बन्धित सुचना</h3>
              <Multiselect
                v-model="fiscalYear"
                :options="years"
                :placeholder="'आर्थिक वर्ष छान्नुहोस्'"
                :canClear="false"
              />
            </div>
          </div>
          <SectorStats
            :decisionCount="getDecisionCount"
            :policyCount="getPolicyCount"
            :totalBudget="getBudgetCount"
          />
          <SectorInformation
            :policies="policies"
            :decisions="decisions"
            :bugetdetails="bugetdetails"
            :fiscalYear="fiscalYear"
          />
        </tab>
        <tab
          :name="`${sectorTypes[type]} क्षेत्र सम्बन्धित गुनासो/ प्रतिक्रिया`"
        >
          <div class="grievance-wrapper">
            <GrievanceComponents :sectorType="type" />
          </div>
        </tab>
      </tabs>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { Tabs, Tab } from "vue3-tabs-component";
import Multiselect from "@vueform/multiselect";
import LocalGovRepository from "./../../repositories/LocalGovernmentRepository";
import BudgetRepository from "./../../repositories/Budget";
import { nepaliToEnglishNumber, englishToNepaliNumber } from "nepali-number";
const SectorDescription = defineAsyncComponent(() =>
  import("../../components/sector/sectorDescription.vue")
);
const SectorStats = defineAsyncComponent(() =>
  import("../../components/sector/sectorStats.vue")
);
const SectorInformation = defineAsyncComponent(() =>
  import("../../components/sector/sectorInformation.vue")
);
const SectorBlog = defineAsyncComponent(() =>
  import("../../components/sector/sectorBlog.vue")
);
const GrievanceComponents = defineAsyncComponent(() =>
  import("./../../views/grievance/GrievanceComponents.vue")
);

export default {
  components: {
    Tab,
    Tabs,
    SectorStats,
    SectorDescription,
    SectorInformation,
    SectorBlog,
    Multiselect,
    GrievanceComponents,
  },
  data() {
    return {
      sectorTypes: {
        health: "स्वास्थ्य",
        education: "शिक्षा",
        agriculture: "कृषि",
        water_sanitation: "पानी र सरसफाइ",
      },
      type: this.$route.params.type,
      years: ["२०७६-२०७८"],
      default: {
        placeholder: "multiselect-placeholder",
      },
      fiscalYear: "२०७८-२०७९",
      policies: [],
      decisions: [],
      budgets: [],
      bugetdetails: [],
      englishToNepaliNumber,
      gradient: {
        to: "#BB4E75",
        from: "#FF9D6C",
      },
    };
  },
  async mounted() {
    this.$store.dispatch("setLoading", false);
    this.getPolicies();
    this.getDecisions();
    this.getBudgets();
    this.getBudgetDetails();
  },
  methods: {
    async getPolicies() {
      let params = `?sector=${
        this.$store.state.sectors[this.type].name
      }&fiscalYear=${nepaliToEnglishNumber(this.fiscalYear)}`;
      let { data } = await LocalGovRepository.getPolicies(params);

      if (data.data) {
        this.policies = data.data;
      }

      if (data.metadata) {
        this.years = [...new Set([...this.years, ...data.metadata.years])];
      }
    },
    async getDecisions() {
      let params = `?sector=${
        this.$store.state.sectors[this.type].name
      }&fiscalYear=${nepaliToEnglishNumber(this.fiscalYear)}`;
      let { data } = await LocalGovRepository.getDecisions(params);

      if (data.data) {
        this.decisions = data.data;
      }

      if (data.metadata) {
        this.years = [...new Set([...this.years, ...data.metadata.years])];
      }
    },
    async getBudgets() {
      let { data } = await BudgetRepository.getBudget();

      if (data.data) {
        this.budgets = data.data;
      }

      if (data.metadata) {
        this.years = [...new Set([...this.years, ...data.metadata.years])];
      }
    },
    async getBudgetDetails() {
      let { data } = await BudgetRepository.getBudgetDetails(
        nepaliToEnglishNumber(this.fiscalYear)
      );

      if (data.status) {
        this.bugetdetails = data.data;
      }
    },
    formModalOn() {
      this.$store.dispatch("setModalForm", true);
    },
  },
  watch: {
    fiscalYear: {
      handler() {
        this.getPolicies();
        this.getDecisions();
        this.getBudgetDetails();
      },
    },
  },
  computed: {
    getDecisionCount() {
      let decisionCount = 0;

      for (const i in this.decisions) {
        decisionCount += this.decisions[i].decision_listings.length;
      }

      return englishToNepaliNumber(decisionCount);
    },
    getPolicyCount() {
      let policyCount = 0;

      for (const i in this.policies) {
        for (const j in this.policies[i].policies) {
          policyCount += this.policies[i].policies[j].descriptions.length;
        }
      }

      return englishToNepaliNumber(policyCount);
    },
    getBudgetCount() {
      let totalBuget = 0;
      let budget = {};

      for (const i in this.budgets) {
        if (this.budgets[i].year == this.fiscalYear) {
          budget = this.budgets[i];
        }
      }

      if (budget.sector_json) {
        totalBuget =
          budget.sector_json[this.$store.state.sectors[this.type].name];
      }

      return englishToNepaliNumber(totalBuget);
    },
  },
};
</script>

<style lang="scss">
.sector {
  &-wrapper {
    padding-bottom: 80px;
    h3 {
      font-weight: 600;
    }
  }
  &__content {
    max-width: 875px;
    padding: 0 20px;
  }
  &__card {
    background: $neutrals-white;
    border-radius: 20px;
    padding: 40px;
  }
}

.go-to-form {
  background: linear-gradient(170deg, #f97794 0%, #623aa2 100%);
  border-radius: 8px;
  height: 80px;
  &:hover {
    background: linear-gradient(170deg, #623aa2 0%, #f97794 100%);
  }
}

.open-form {
  align-items: center;
  color: $neutrals-white;
  cursor: pointer;
  display: flex;
  line-height: 80px;
  font-size: 24px;
  font-weight: 700;
  justify-content: center;
  &:before {
    background: url(../../assets/ic_sprite.svg) no-repeat 0 -194px;
    content: "";
    display: block;
    height: 24px;
    margin-right: 8px;
    width: 24px;
  }
}
</style>
